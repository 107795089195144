import { Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ItemActionLists from '../../components/items/actionList';
import LandActionLists from '../../components/lands/actionList';
import { useStores } from '../../contexts/storesContext';
import GA from '../../utils/ga';

const GameInventoryStyled = styled.div`
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyleCardHeader = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas: revert;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`inventory-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const CommonTabs = styled(props => (
  <Tabs classes={{ indicator: 'indicator' }} {...props} />
))`
  & .indicator {
    background-color: ${({ theme }) => theme.primary};
    height: 3px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

function a11yProps(index) {
  return {
    id: `common-tab-${index}`,
    'aria-controls': `offer-detail-tabpanel-${index}`,
  };
}

const StyledTabTitle = styled.div`
  display: flex;
  padding: 10px 6px;
  align-items: center;
  font-weight: bold;
  img {
    margin-right: 10px;
    height: 29px;
  }
  white-space: nowrap;
`;

const CommonTab = styled(({ index, ...props }) => (
  <Tab
    classes={{ selected: 'selected' }}
    id={`councilTab-tab-${index}`}
    aria-controls={`councilTab-tabpanel-${index}`}
    {...props}
  />
))`
  && {
    font-size: 1em;
    font-weight: 700;
    text-transform: none;
    min-width: unset;
  }
`;

const GameInventory = observer(() => {
  const {
    root: { userStore },
  } = useStores();
  const [valueTab, setValueTab] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState<boolean>(false);

  function handleChangeTab(e, value) {
    setValueTab(value);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { balances, profile, getBalanceWland, getBalanceNftItem } = userStore;

  useEffect(() => {
    if (!profile.email) {
      return;
    }
    setLoading(true);
    if (valueTab === 1) {
      getBalanceNftItem({ page, size: 20 }).finally(() => {
        setLoading(false);
      });
    } else if (valueTab === 0) {
      getBalanceWland().finally(() => {
        setLoading(false);
      });
    }
  }, [getBalanceNftItem, profile.email, valueTab, page, getBalanceWland]);

  useEffect(() => {
    GA.mountedPage({
      page: 'game-inventory',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { nftitem, wland } = balances;

  return (
    <GameInventoryStyled>
      <StyleCardHeader>
        <CommonTabs
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="tabs"
          classes={{ indicator: 'indicator' }}
        >
          <CommonTab
            label={
              <StyledTabTitle>
                <img src={require('../../assets/images/tab-land.png')} alt="" />
                Lands
              </StyledTabTitle>
            }
            {...a11yProps(0)}
          />
          {/* <CommonTab
            label={
              <StyledTabTitle>
                <img src={require('../../assets/images/tab-item.png')} alt="" />
                Items
              </StyledTabTitle>
            }
            {...a11yProps(1)}
          /> */}
        </CommonTabs>
        <TabPanel value={valueTab} index={0}>
          <LandActionLists
            listData={wland}
            loading={isLoading}
            type="metaData"
            page={1}
            totalPage={1}
            onChangePage={() => {}}
          />
        </TabPanel>
        {/* <TabPanel value={valueTab} index={1}>
          <ItemActionLists
            listData={nftitem.data}
            loading={isLoading}
            type="metaData"
            page={page}
            totalPage={nftitem.totalPage}
            onChangePage={handleChangePage}
          />
        </TabPanel> */}
      </StyleCardHeader>
    </GameInventoryStyled>
  );
});

export default GameInventory;
