import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TagID } from '../../components/Common';
import { SEASON } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import { WlandMetaDataProps } from '../../types';
import {
  abbreviateNumber,
  amountFormat,
  bnum,
  fromWei,
} from '../../utils/helpers';
import {
  getColorByEnvironment,
  getImageRareByName,
  getImageSeasonByName,
} from '../../utils/land';
import { getAttributeByName } from '../../utils/subgraph';

const StyledWrapStat = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '33.33333%'};
  padding: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 50%;
    padding: 10px 4px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    padding: 5px 4px;
  `}
`;

const StyledStat = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  max-width: calc(100% - 45px);
`;

const StyledIcon = styled.div`
  margin-right: 8px;
  width: 30px;
  min-width: 30px;
  height: 35px;
  text-align: center;
  img {
    height: 100%;
    max-height: 35px;
    max-width: 30px;
    object-fit: contain;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-height: 32px;
      max-width: 28px;
    `}
  }
`;

const LabelAttribute = styled.div`
  font-weight: 700;
  color: #1dc48c;
  @media screen and (max-width: 1500px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

const StyledItemWithLink = styled(Link)`
  text-decoration: none;
  color: unset;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px;
  padding: 0 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0 8px rgb(124 238 252);
  }
  background: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 12px 12px;
  `};
`;

const StyledItem = styled.div`
  text-decoration: none;
  color: unset;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px;
  padding: 0 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0 8px rgb(124 238 252);
  }
  background: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 12px 12px;
  `};
`;

const StyledLand = styled.img`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledBox = styled.img`
  height: auto;
  width: 100%;
  max-width: 140px;
`;

const StyledBgItem = styled.img`
  height: auto;
  width: 100%;
`;

const StyledOrderPrice = styled.div`
  line-height: 1.3px;
  font-weight: 500;
  span {
    font-size: 20px;
  }
  .number-decimal {
    font-size: 18px;
    color: #98a0b9;
  }
  @media screen and (max-width: 1500px) {
    span {
      font-size: 18px;
    }
    .number-decimal {
      font-size: 16px;
    }
  }
`;

const StyledContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100px;
  img.item {
    height: auto;
    width: 60%;
  }
`;

const StyledSeason = styled.img`
  width: 28px;
  height: 28px;
  filter: grayscale(1);
  &.active {
    filter: grayscale(0);
  }
`;

const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: 12%;
  padding: 10px;
  min-height: 165px;
  height: auto;
  @media screen and (max-width: 1700px) {
    min-height: 140px;
  }
  @media screen and (max-width: 1439px) {
    width: 33%;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const StyledHeaderInfo = styled.div`
  display: grid;
  grid-gap: 10px;
  height: max-content;
  width: 14%;
  padding: 10px;
  @media screen and (max-width: 1439px) {
    width: 33%;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const StyledStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 48%;
  @media screen and (max-width: 1439px) {
    flex: 100%;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const StyledBottom = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 14%;
  padding: 10px;
  white-space: nowrap;
  margin-left: auto;
  @media screen and (max-width: 1439px) {
    width: 33%;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const StyledSeasonWrap = styled.div`
  display: flex;
  img:not(:last-child) {
    margin-right: 6px;
  }
`;

const StyleName = styled.div``;

interface ViewListItemProps {
  landItem: WlandMetaDataProps;
  type: 'wland' | 'metaData';
  actionNode?: React.ReactNode;
}

const ListItem = observer(({ landItem, type = 'wland', actionNode }) => {
  const {
    root: { tokenStore },
  } = useStores();
  const isWland = type === 'wland';

  const isPacked = !!JSON.parse(landItem?.isPacked || 'false');
  if (!isWland && landItem && landItem?.attributes) {
    landItem.rare = getAttributeByName('Rare', landItem?.attributes)?.value;
    landItem.level = getAttributeByName('Level', landItem?.attributes)?.value;
    landItem.season = getAttributeByName('Season', landItem?.attributes)?.value;
    landItem.birth = getAttributeByName('Birth', landItem?.attributes)?.value;
    landItem.increaseMutantRate = getAttributeByName(
      'IncreaseMutantRate',
      landItem?.attributes
    )?.value;
    landItem.timeReduce = getAttributeByName(
      'TimeReduce',
      landItem?.attributes
    )?.value;
  }
  const numSeasons = landItem.season?.split(',').length;
  return (
    <>
      <StyledHeader>
        {isPacked ? (
          <StyledBox className={'nft-unbox'} src={landItem?.image} alt="" />
        ) : (
          <StyledContent>
            <StyledBgItem
              src={getImageRareByName(landItem?.rare)}
              alt={landItem?.rare}
            />
            <StyledLand className={'item'} src={landItem?.image} alt="" />
          </StyledContent>
        )}
      </StyledHeader>
      <StyledHeaderInfo>
        <TagID
          color={getColorByEnvironment(landItem?.environment)}
          to={actionNode && `/lands/${landItem.nftItemId || landItem.itemId}`}
        >
          #{landItem.nftItemId || landItem.itemId}
        </TagID>
        <StyleName className="text-truncate">{landItem?.name}</StyleName>
        {!isPacked && (
          <StyledSeasonWrap>
            {SEASON.map(season => (
              <StyledSeason
                src={getImageSeasonByName(season)}
                className={`${season?.toLowerCase()} ${
                  landItem.season
                    ?.split(',')
                    ?.findIndex(v => v?.toLowerCase() === season) !== -1
                    ? 'active'
                    : ''
                }`}
                key={season + 'season'}
              />
            ))}
          </StyledSeasonWrap>
        )}
      </StyledHeaderInfo>

      <StyledStats>
        {!isPacked && (
          <>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-rare.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Rare</LabelAttribute>
                <div>{landItem.rare || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-birth.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Birth</LabelAttribute>
                <div>{landItem.nftItemId || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-season.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Season</LabelAttribute>
                <div>
                  {numSeasons || numSeasons === 0
                    ? numSeasons === 1
                      ? `${numSeasons} season`
                      : `${numSeasons} seasons`
                    : '__'}
                </div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-level.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Level</LabelAttribute>
                <div>{landItem.level || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-mutation.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Increase Mutant Rate</LabelAttribute>
                <div>
                  {landItem?.increaseMutantRate
                    ? `${bnum(landItem?.increaseMutantRate?.toString())
                        .multipliedBy(100)
                        .toFixed()}%`
                    : '0%'}
                </div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-time-reduce.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Time Reduce</LabelAttribute>
                <div>
                  {landItem?.timeReduce
                    ? `${bnum(landItem?.timeReduce?.toString())
                        .multipliedBy(100)
                        .toFixed()}%`
                    : '0%'}
                </div>
              </StyledStat>
            </StyledWrapStat>
          </>
        )}
      </StyledStats>

      {isWland && (
        <StyledBottom>
          <StyledOrderPrice>
            <ShoppingCartIcon
              style={{ fontSize: '20px', marginRight: '3px' }}
            />
            {landItem.onSale ? (
              <>
                <span>
                  {
                    abbreviateNumber(
                      amountFormat(fromWei(landItem.salePrice || '0'), 3),
                      0
                    )?.split('.')[0]
                  }{' '}
                </span>
                <span className="number-decimal">
                  {abbreviateNumber(
                    amountFormat(fromWei(landItem.salePrice || '0'), 3),
                    0
                  )?.split('.')[1] &&
                    `.${
                      abbreviateNumber(
                        amountFormat(fromWei(landItem.salePrice || '0'), 3),
                        0
                      )?.split('.')[1]
                    }`}{' '}
                </span>
              </>
            ) : (
              '__'
            )}
          </StyledOrderPrice>
          <div style={{ color: '#98a0b9' }}>
            {landItem.onSale && tokenStore.priceWANA
              ? `≈ $${amountFormat(
                  bnum(fromWei(landItem.salePrice || '0')).multipliedBy(
                    bnum(tokenStore.priceWANA)
                  ),
                  0
                )}`
              : ''}
          </div>
        </StyledBottom>
      )}
      {actionNode}
    </>
  );
});

const ViewListItem = ({
  landItem,
  type = 'wland',
  actionNode,
}: ViewListItemProps) => {
  return actionNode ? (
    <StyledItem>
      <ListItem landItem={landItem} actionNode={actionNode} type={type} />
    </StyledItem>
  ) : (
    <StyledItemWithLink to={`/lands/${landItem.itemId}`}>
      <ListItem landItem={landItem} actionNode={actionNode} type={type} />
    </StyledItemWithLink>
  );
};

export default ViewListItem;
