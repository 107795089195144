import { BscConnector } from '@binance-chain/bsc-connector';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ChainId } from '../constants';
import { NetworkConnector } from './NetworkConnector';

const POLLING_INTERVAL = 12000;
export const RPC_URLS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_RPC_URL_56 as string,
  [ChainId.CHAPEL]: process.env.REACT_APP_RPC_URL_97 as string,
};

export const SUBGRAPH_URLS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_MARKETPLACE_GRAPHQL,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_MARKETPLACE_GRAPHQL,
};

export const SUBGRAPH_LOTTERY_URLS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_LOTTERY_GRAPHQL,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_LOTTERY_GRAPHQL,
};

export const API_URLS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_URL_56 as string,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_URL_97 as string,
};

export const API_V2_URLS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_V2_URL_56 as string,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_V2_URL_97 as string,
};

export const DISTRIBUTOR_URLS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_DISTRIBUTOR as string,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_DISTRIBUTOR as string,
};

export const WHITELIST_URLS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_WHITELIST as string,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_WHITELIST as string,
};

export const GAME_SEVER_URLS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: process.env.REACT_APP_API_GAME as string,
  [ChainId.CHAPEL]: process.env.REACT_APP_API_GAME as string,
};

// tslint:disable-next-line: radix
export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_SUPPORTED_NETWORK_ID ?? '56'
);

export const CONTRACT_PRICE_WANA: { [chainId in ChainId]?: any } = {
  [ChainId.MAINNET]: {
    USDT: {
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      symbol: 'BUSD',
      decimals: '18',
    },
    PairUsdtNative: '0x65b51bc890C24C0Da163289B68480020222B4332',
  }, // pair WANA-BUSD
  [ChainId.CHAPEL]: {
    USDT: {
      address: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
      symbol: 'BUSD',
      decimals: '18',
    },
    PairUsdtNative: '0x22ABa1512Aa98574406bdbAea5e1aB312590a310',
  }, // pair WANA-BUSD
};

export const CONTRACT_PRICE_WAI: { [chainId in ChainId]?: any } = {
  [ChainId.MAINNET]: {
    USDT: {
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      symbol: 'BUSD',
      decimals: '18',
    },
    PairUsdtNative: '0xc2b068161d308ab9794098992ad036b23e0b57fb',
  }, // pair WAI-BUSD
  [ChainId.CHAPEL]: {
    USDT: {
      address: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
      symbol: 'BUSD',
      decimals: '18',
    },
    PairUsdtNative: '0x4c0b578299ff7767336f2c6deab1ce43501d6897',
  }, // pair WAI-BUSD
};

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: RPC_URLS[ChainId.MAINNET] },
});

export const SUPPORTED_CHAINS = [ChainId.MAINNET, ChainId.CHAPEL];

// MetaMask
export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINS,
});

// Trust Wallet
export const walletConnect = new WalletConnectConnector({
  infuraId: '5c4e3b95f6644a59896b31e4b24d528e',
  rpc: { 56: RPC_URLS[ChainId.MAINNET], 97: RPC_URLS[ChainId.CHAPEL] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});
// coinbase
export const walletLink = new WalletLinkConnector({
  url: `https://marketplace.wanakafarm.com`,
  appName: 'WanakaMarketplace',
  appLogoUrl: '/images/favicon.png',
  supportedChainIds: SUPPORTED_CHAINS,
});

export const bscConnector = new BscConnector({
  supportedChainIds: SUPPORTED_CHAINS,
});

export const CHAIN_NAMES = {
  MAINNET: 'mainnet',
  CHAPEL: 'kovan',
};

export const chainNameById = {
  [ChainId.MAINNET.toString()]: 'mainnet',
  [ChainId.CHAPEL.toString()]: 'kovan',
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
  downloadLink?: {
    desktop: string;
  };
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true,
  },
  COINBASE_WALLET: {
    connector: walletLink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Connect to Coinbase Wallet.',
    href: null,
    color: '#4196FC',
  },
  TRUST_WALLET: {
    connector: injected,
    name: 'Trust Wallet',
    iconName: 'trust.png',
    description: 'Connect to Trust Wallet.',
    href: null,
    color: '#4196FC',
    downloadLink: {
      desktop:
        'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph/related',
    },
  },
  WALLET_CONNECT: {
    connector: walletConnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  // BSC_WALLET: {
  //   connector: bscConnector,
  //   name: 'Binance Chain Wallet',
  //   iconName: 'bsc-wallet.svg',
  //   description: 'Connect to Binance Chain Wallet.',
  //   href: null,
  //   color: '#E8831D',
  //   mobile: true,
  // },
};
