import { ValidationStatus } from './stores/actions/validators';
import { BigNumber } from './utils/bignumber';

export interface BigNumberMap {
  [index: string]: BigNumber;
}

export interface StringMap {
  [index: string]: string;
}

export interface NumberMap {
  [index: string]: number;
}

// Token Address -> checked
export interface CheckboxMap {
  [index: string]: Checkbox;
}

// Token -> amount
export interface InputMap {
  [index: string]: Input;
}

export interface Input {
  value: string;
  touched: boolean;
  validation: ValidationStatus;
}

export interface Checkbox {
  checked: boolean;
  touched: boolean;
}

export interface EnvironmentProps {
  id: string;
  name: string;
  checked: boolean;
}

export interface ClearFilterProps {
  yourOffers?: boolean;
  yourOldOffers?: boolean;
  yourRented?: boolean;
  rares?: boolean;
  environments?: boolean;
  itemId?: boolean;
  sale?: boolean;
  sortBy?: boolean;
  page?: boolean;
  isOwned?: boolean;
  yourOffer?: boolean;
  rangePrice?: boolean;
  checkedTypeIds?: boolean;
  rangeLV?: boolean;
}

export interface OrderProps {
  id: number;
  orderId: string;
  itemId: number;
  owner: string;
  buyer: string;
  price: string;
  status: string;
}
export interface WlandMetaDataProps {
  id: number;
  itemId: number;
  nftItemId: string;
  metaDataUrl: string;
  owner: string;
  name: string;
  description: string;
  isActive: boolean;
  salePrice: string;
  environment: string;
  inInventory: string;
  image: string;
  isPacked: string;
  rare: string;
  season: string;
  increaseMutantRate: number;
  timeReduce: number;
  level: number;
  birth: number;
  activeOrder: OrderProps;
  onSale: boolean;
  saleAt: number;
  visible?: boolean;
  contractAddress?: string;
  attributes?: any[];
}

export interface NftMetaDataProps {
  rare: string;
  id: number;
  itemId: number;
  metaDataUrl: string;
  owner: string;
  name: string;
  description: string;
  isActive: boolean;
  salePrice: string;
  activeOrder: OrderProps;
  environment: string;
  image: string;
  typeId: number;
  typeName: string;
  category: string;
  weight: number;
  quality: number;
  quantityCanClaim: number;
  maxWaiCanClaim: number;
  maxWaiReward: number;
  onSale: boolean;
  saleAt: number;
  visible?: boolean;
  level: number;
  contractAddress?: string;
  attributes?: any[];
}
export interface OfferProps {
  id: number;
  offerId: string;
  itemId: number;
  category: string;
  nftAddress: string;
  txHash: string;
  seller: string;
  buyer: string;
  price: string;
  status: string;
  blockNumber: number;
  expiresAt: number;
  timestamp: number;
}
export interface SaleHistoryProps {
  id: number;
  txHash: string;
  type: string;
  category: string;
  itemId: number;
  quantity: number;
  price: string;
  from: string;
  to: string;
  blockNumber: string;
  timestamp: number;
}

export interface NftParamProps {
  itemId?: string;
  nftItemId?: number;
}

export enum TokenProps {
  WANA = 'WANA',
  WAI = 'WAI',
  WLAND = 'WLAND',
  NFTITEM = 'NFTITEM',
}

export enum TokenParamProps {
  amount = 'amount',
  itemId = 'itemId',
  itemIds = 'itemIds',
}

export enum TokenTypeProps {
  nftItem = 'nftItem',
  wland = 'wland',
}

export interface MapCellsProps {
  type: number;
  status: number;
  contract_id: number;
  item_flg: boolean;
  posX: number;
  posY: number;
}

export interface DataLandContractProps {
  id?: number;
  name?: string;
  cell_list?: MapCellsProps[];
  attributes?: any[];
}

export interface ViewModeProps {
  ownedCreate: boolean;
  ownedView: boolean;
  ownedEdit: boolean;
  ownedRented: boolean;
  renterView: boolean;
  ownedContract: boolean;
  rentedView: boolean;
  ownedClaim: boolean;
}

export interface MailProps {
  title?: string;
  id?: number;
  content?: string;
  reward_datas?: any[];
  sender_name?: string;
  read_flg: boolean;
  expiration_date?: string;
  sent_date?: string;
}
