import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { TokenProps, WlandMetaDataProps } from '../../types';
import Spinner from '../Spinner';
import ViewListItem from './viewListItem';
import Pagination from '@material-ui/lab/Pagination';
import { NavLink } from 'react-router-dom';
import { getAttributeByName } from '../../utils/subgraph';
import Tooltip from '../Tooltip';
import { NoDataText } from '../Common';

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: calc(100vh - 540px);
  `};
  a {
    text-decoration: none;
  }
`;

const StyledAction = styled.div<{ width: string }>`
  text-align: right;
  width: ${({ width }) => width};
  padding: 10px;
  margin-left: auto;
  display: grid;
  grid-gap: 10px;
  justify-content: flex-end;
  grid-auto-flow: column;
  @media screen and (max-width: 1439px) {
    width: 33%;
    text-align: left;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.borderColor};
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    text-transform: capitalize;
  }
`;

interface GridLandsProps {
  listData: WlandMetaDataProps[];
  loading?: boolean;
  type: 'wland' | 'metaData';
  page: number;
  totalPage: number;
  onChangePage: any;
}

const GridLands = observer(
  ({
    listData,
    loading,
    type,
    page,
    totalPage,
    onChangePage,
  }: GridLandsProps) => {
    const {
      root: {
        providerStore,
        userStore,
        dropdownStore,
        notificationModalStore,
        withdrawStore,
      },
    } = useStores();
    const { account } = providerStore.providerStatus;
    const { setPopupDeposit } = userStore;
    const { setPopupWithdraw } = withdrawStore;
    const isWland = type === 'wland';
    const onDeposit = (itemId, activeOrder) => {
      if (!account) {
        dropdownStore.toggleWalletDropdown();
        return;
      }
      if (activeOrder) {
        notificationModalStore.showNotification({
          text: 'Please Cancel Listing before Deposit',
        });
        return;
      }
      setPopupDeposit({
        open: true,
        isEdit: false,
        param: itemId,
        token: TokenProps.WLAND,
      });
    };

    const onWithdraw = (itemId, nftItemId, withdrawable) => {
      if (!account) {
        dropdownStore.toggleWalletDropdown();
        return;
      }
      if (!withdrawable) {
        notificationModalStore.showNotification({
          text: 'Please put this Land to game Inventory',
        });
        return;
      }
      setPopupWithdraw({
        open: true,
        isEdit: false,
        param: { itemId, nftItemId },
        token: TokenProps.WLAND,
      });
    };

    return (
      <StyledItems>
        {listData?.map((landItem, index) => {
          return (
            <ViewListItem
              key={index}
              landItem={landItem}
              type={type}
              actionNode={
                <StyledAction width={isWland ? '12%' : '24%'}>
                  {isWland ? (
                    <>
                      {landItem.isPacked === 'true' ? (
                        <></>
                      ) : (
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            onDeposit(landItem.itemId, landItem.onSale);
                          }}
                        >
                          Deposit
                        </StyledButton>
                      )}
                    </>
                  ) : (
                    <>
                      {/* {withdrawable || landItem.isPacked ? (
                        <Tooltip
                          title={
                            landItem.isPacked ? '' : 'Please put Land on Map'
                          }
                        >
                          <span>
                            <StyledButton
                              variant="outlined"
                              color="primary"
                              className="primary"
                              disabled={withdrawable || landItem.isPacked}
                            >
                              For Rent
                            </StyledButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <NavLink to={`/rental/lands/${landItem.id}`}>
                          <StyledButton
                            variant="outlined"
                            color="primary"
                            className="primary"
                          >
                            For Rent
                          </StyledButton>
                        </NavLink>
                      )} */}

                      <StyledButton
                        variant="contained"
                        color="primary"
                        className="primary2"
                        onClick={() => {
                          onWithdraw(landItem.itemId, landItem.nftItemId, true);
                        }}
                      >
                        Withdraw
                      </StyledButton>
                    </>
                  )}
                </StyledAction>
              }
            />
          );
        })}

        <div>
          <Spinner loading={loading} />
          {(!listData || (listData && listData.length === 0)) && !loading ? (
            <NoDataText size="18px" />
          ) : (
            <Pagination
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
              variant="outlined"
              color="primary"
              size="large"
              page={page}
              count={totalPage}
              onChange={onChangePage}
            />
          )}
        </div>
      </StyledItems>
    );
  }
);

export default GridLands;
